@import 'sources/styles/variables.scss';

.playlistWrapper {
  display: flex;
  flex-direction: column;

  .playlist {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 25px 25px 25px 33px;
    display: flex;
    width: 100%;

    .img {
      margin-right: 21px;
      width: 216px;
      height: 148px;
      border-radius: 4px;
      cursor: pointer;
    }

    .description {
      display: flex;
      flex-direction: column;

      .imported {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $dark-nav;
        border-radius: 100px;
        height: 26px;
        color: #ffffff;
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        max-width: 106px;
        margin-bottom: 14px;
      }

      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $dark-nav;
        cursor: pointer;
      }

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $dark-nav;
        max-width: 360px;
      }
    }

    .controls {
      margin-left: auto;
      width: 368px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .syncPlaylist,
      .useDescription {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .switch {
          background: #deefff;
          width: 48px;
          height: 24px;
          opacity: 0.5;

          :global {
            .ant-switch-handle::before {
              background: #0078ff !important;
            }
          }
        }

        .description {
          position: relative;
        }

        :global {
          .ant-switch-disabled,
          .ant-switch-disabled * {
            cursor: pointer !important;
          }
        }

        :global {
          .ant-switch {
            .ant-switch-handle::before {
              background: #d0dfed !important;
            }
          }

          .ant-switch-checked {
            opacity: 1 !important;

            .ant-switch-handle::before {
              background: #0078ff !important;
            }
          }
        }
      }

      .disabledText {
        opacity: 0.5;
      }

      .syncPlaylistText,
      .useDescriptionText {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $dark-nav;
      }

      .syncPlaylist {
        margin-bottom: 10px;
      }

      .buttons {
        margin-top: auto;
        display: flex;
        justify-content: space-between;

        .addUnimportedBtn {
          background: #0078ff;
          height: 38px;
          color: #ffffff;
          border: none;
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
          display: flex;
          align-items: center;
          color: #ffffff;
          min-width: 200px;
          justify-content: center;

          &:hover {
            background: #0078ff;
            color: #ffffff;
          }
        }

        .addAllVideosBtn {
          background: #0078ff;
          height: 38px;
          color: #ffffff;
          border: none;
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
          display: flex;
          align-items: center;
          color: #ffffff;
          min-width: 150px;
          justify-content: center;

          &:hover {
            background: #0078ff;
            color: #ffffff;
          }
        }

        .disabledBtn {
          background: #c1d1e3;
          color: #ffffff;

          &:hover {
            background: #c1d1e3;
            color: #ffffff;
          }
        }
      }
    }
  }
}
