@import 'sources/styles/variables.scss';

.mainWrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  // max-width: 1440px;
  margin: 0 auto;
  // border-bottom: 1px solid $light-blue;

  .mainContent {
    display: flex;
    align-items: stretch;
    flex: 1;

    .section {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px 32px 0 32px;
      // min-height: 630px;

      span {
        height: 100%;
      }

      .uploadWrapper {
        background: #ffffff;
        border: 2px dashed $light-stroke;
        border-radius: 4px;

        &:hover {
          border: 2px dashed $light-stroke;
        }

        .text {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: $dark-nav;
          max-width: 158px;
          margin: 0 auto 25px auto;

          span {
            font-weight: 400;
          }
        }

        .uploadBtn {
          background: $primary;
          color: #ffffff;
          border: none;
          width: 212px;
          height: 38px;

          span {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.spinner {
  position: relative !important;
  top: 44% !important;
  left: 46%;
}

.tempMediaItem {
  background: linear-gradient(
      0deg,
      rgba(0, 27, 57, 0.33),
      rgba(0, 27, 57, 0.33)
    ),
    #f6fafe;
  border: 2px dashed #d1dbe5;
  border-radius: 10px;
  min-width: 216px;
  max-width: 216px;
  height: 148px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  position: relative;
  overflow: hidden;
}
