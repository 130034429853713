@import 'sources/styles/variables.scss';
@import 'sources/styles/mixins.scss';

.mainWrapper {
  @include main-wrapper;

  .mainContent {
    display: flex;
    align-items: stretch;

    .section {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;

      .pageTitleWrapper {
        padding: 26px 24px 26px 34px;
        border-bottom: 1px solid $light-blue;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 50px;
        left: 249px;
        width: calc(100% - 252px);
        z-index: 1000;
        background-color: #ffffff;

        .pageTitle {
          margin-bottom: 0;
          font-weight: 700;
          font-size: 28px;
          line-height: 32px;
          color: #001b39;
          flex: 1;
        }
      }

      .content {
        padding: 116px 32px 0 16px;
        flex: 1;

        .topInfo {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          z-index: 10;
          position: relative;
        }

        .mainInfo {
          display: flex;
          width: 100%;
          position: relative;
          max-height: 880px;

          .bgColors {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 47%;
            width: 100%;
            height: 880px;
            max-height: 880px;
            transform: translate(-50%, -50%) !important;
          }

          .diagrams {
            display: flex;
            flex-direction: column;
            flex: 1;
            z-index: 10;

            .lineDiagramWrapper {
              background: #ffffff;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
              border-radius: 16px;
              margin-bottom: 16px;
              padding: 24px;
            }

            .geoDiagramWrapper {
              background: #ffffff;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
              border-radius: 16px;
              padding: 24px 24px 45px 24px;
              overflow-y: auto;

              &::-webkit-scrollbar {
                height: 5px;
                width: 10px;
              }

              &::-webkit-scrollbar-track {
                background: #c8c8c8;
                border-radius: 22px;
              }

              &::-webkit-scrollbar-thumb {
                background: #0078ff;
                border-radius: 22px;
                max-width: 10px;
              }

              .topGeoDiagram {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #e7f1fb;
                padding: 8px 0;

                .title {
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  color: #242946;
                  display: block;
                }

                .geoShowMoreBtn {
                  background-color: #ffffff;
                  border: 1px solid #e7f1fb;
                  cursor: pointer;

                  &:hover,
                  &:active {
                    background-color: #ffffff;
                    border: 1px solid #e7f1fb;
                  }

                  span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    display: flex;
                    align-items: center;
                    color: #242946;
                  }
                }
              }

              .geoDiagram {
                padding-top: 10px;

                .geoDiagramInfoWrapper {
                  display: grid;
                  grid-template-columns: repeat(5, 1fr);
                  grid-template-rows: repeat(2, 1fr);
                  grid-column-gap: 10px;
                  grid-row-gap: 10px;

                  .geoPlotWrapper {
                    position: relative;
                    align-self: flex-start;
                    float: left;
                    height: 310px;
                    margin-right: 12px;
                    grid-area: 1 / 1 / 3 / 3;

                    .totalWrapper {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      position: absolute;
                      width: 150px;
                      height: 50px;
                      left: 73px;
                      top: 122px;
                      z-index: 10;

                      .text {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: #4e5969;
                      }

                      .number {
                        font-weight: 700;
                        font-size: 40px;
                        line-height: 100%;
                        text-align: center;
                        color: #0078ff;
                      }
                    }
                  }

                  .geoDiagramInfoItem {
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    margin-bottom: 20px;
                    margin-right: 20px;
                    min-height: 68px;
                    height: 68px;
                    max-height: 68px;
                    justify-content: center;
                    min-width: 100px;
                    margin-top: 38px;

                    .verticalLine {
                      position: absolute;
                      left: 0;
                      height: 0;
                      height: 100%;
                      width: 4px;
                      border-radius: 10px;
                    }

                    .country {
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 22px;
                      display: flex;
                      align-items: flex-start;
                      color: #4e5969;
                      margin-left: 16px;
                      overflow: hidden;

                      p {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        margin: 0;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                      }
                    }

                    .percent {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 28px;
                      display: flex;
                      align-items: flex-start;
                      margin-left: 16px;

                      .percentNumber {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: #242946;
                        margin-right: 4px;
                      }
                    }
                  }
                }
              }
            }
          }

          .mostViewed {
            background: #ffffff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
            border-radius: 16px;
            width: 360px;
            min-width: 360px;
            max-width: 360px;
            padding: 36px 24px 16px;
            z-index: 10;
            overflow: hidden;
            margin-left: 16px;
            display: flex;
            flex-direction: column;

            .title {
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: #242946;
              text-align: left;
              margin-bottom: 23px;
              overflow-x: hidden;
            }

            .mostViewedList {
              list-style: none;
              padding-left: 0;
              margin-bottom: 32px;
              overflow-y: auto;
              max-height: 716px;
              height: 716px;

              &::-webkit-scrollbar {
                height: 5px;
                width: 10px;
              }

              &::-webkit-scrollbar-track {
                background: #c8c8c8;
                border-radius: 22px;
              }

              &::-webkit-scrollbar-thumb {
                background: #0078ff;
                border-radius: 22px;
                max-width: 10px;
              }

              .mostViewedItem {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;

                .mediaItemImg {
                  width: 80px;
                  height: 55px;
                  border-radius: 10px;
                }

                .title {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 19px;
                  color: #001b39;
                  flex: 1;
                  margin-bottom: 0;
                  max-width: 140px;
                  max-height: 60px;
                  overflow: hidden;
                }

                .views {
                  background: #ffffff;
                  border: 1px solid #c1d1e3;
                  border-radius: 70px;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 11px;
                  color: #001b39;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 24px;
                  min-width: 64px;
                  margin-right: 4px;

                  svg {
                    margin-left: 3px;
                  }
                }
              }
            }

            .viewMoreBtn {
              width: 315px;
              border: 1px solid #e7f1fb;
              border-radius: 56px;
              background-color: #ffffff;
              font-weight: 400;
              font-size: 14px;
              line-height: 14px;
              display: flex;
              align-items: center;
              color: #242946;
              text-align: center;
              justify-content: center;
              cursor: pointer;

              &:hover {
                color: #242946;
              }
            }
          }
        }

        .monitiztion {
          padding: 40px 24px 24px 24px;
          background: #ffffff;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 16px;
          width: 100%;
          margin-bottom: 16px;
          margin-top: 16px;
          z-index: 10;

          .title {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #242946;
            margin-bottom: 16px;
            border-bottom: 1px solid #e7f1fb;
            padding-bottom: 16px;
          }

          .frameWrapper {
            display: flex;
            justify-content: space-between;
          }
        }

        .devices {
          padding: 40px 24px 24px 24px;
          background: #ffffff;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 16px;
          width: 100%;
          margin-bottom: 16px;
          z-index: 10;

          .title {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #242946;
            margin-bottom: 16px;
            border-bottom: 1px solid #e7f1fb;
            padding-bottom: 16px;
          }

          .frameWrapper {
            display: flex;
            justify-content: space-between;

            .android,
            .roku,
            .ios {
              width: 100%;
              margin-right: 16px;
              height: 148px;
              background: #ffffff;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
              border-radius: 24px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              padding-left: 16px;

              .value {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #242946;
                margin-bottom: 4px;
              }

              .name {
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 100%;
                text-align: center;
              }
            }

            .ios {
              .name {
                color: #f1557f;
              }
            }

            .roku {
              .name {
                color: #0078ff;
              }
            }

            .android {
              margin-right: 0;

              .name {
                color: #f2b02f;
              }
            }
          }
        }
      }
    }
  }
}

.spinner {
  position: absolute !important;
  top: 50%;
  left: 55%;

  .ant-spin-dot {
    top: 46% !important;
    position: fixed !important;
  }
}

.selectDistributionWrapper {
  position: relative;

  .selectDistribution {
    :global {
      .ant-select-selector {
        border: 1px solid #e7f1fb !important;
        padding: 8px 22px 8px 14px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        min-width: 220px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: #242946 !important;
        cursor: pointer !important;
        border-radius: 20px !important;
        height: 40px !important;
        box-shadow: none !important;
      }

      .ant-select-selection-overflow {
        bottom: 3px !important;
      }

      .ant-select-selection-overflow-item .ant-select-selection-item {
        span {
          display: none !important;
          background-color: #ffffff !important;
        }
      }

      .ant-select-selection-item {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: #242946 !important;
        display: flex !important;
        background-color: #ffffff !important;
        border: none;

        div {
          display: none !important;
        }
      }
    }
  }
}

.distributionItem {
  background-color: #ffffff !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 10px !important;
  color: #000000 !important;

  :global {
    .ant-select-item-option-content {
      max-width: 150px !important;
    }

    div[aria-selected='true'] .selectedTimeImg {
      display: none !important;
      background-image: url('../../sources/images/dashboard/selected-distribution-item.svg') !important;
    }
  }
}

.selectTimeWrapper {
  margin-right: 10px;
  position: relative;

  .selectTime {
    :global {
      .ant-select-selector {
        border: 1px solid #e7f1fb !important;
        padding: 8px 22px 8px 14px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        min-width: 220px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: #242946 !important;
        cursor: pointer !important;
        border-radius: 20px !important;
        height: 40px !important;
        box-shadow: none !important;
      }

      .ant-select-selection-item {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: #242946 !important;
        display: flex !important;

        div {
          display: none !important;
        }
      }

      // .ant-select-item-option-active {
      //   background-color: #ffffff !important;
      // }
    }
  }
}

.timeItem {
  background-color: #ffffff !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 10px !important;
  color: #000000 !important;

  :global {
    .ant-select-item-option-content {
      max-width: 150px !important;
    }

    div[aria-selected='true'] .selectedTimeImg {
      display: none !important;
      background-image: url('../../sources/images/dashboard/selected-time-item.svg') !important;
    }
  }
}

.selectedTimeImg,
.selectedDistributionImg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 10px;
  background-image: url('../../sources/images/libary/unselected-category.svg');
  background-position: center;
  background-size: cover;
}

.customTimeBtn {
  border: none !important;
  display: flex !important;
  align-items: center !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #242946 !important;
  border: 1px solid #ffffff !important;
  width: 194px !important;
  box-shadow: 0px 1px 4px rgba(36, 41, 70, 0.15) !important;
  border-radius: 40px !important;
  // background-color: #f5f5f5 !important;

  span {
    margin-left: 8px !important;
    position: relative;
    top: 1px;
  }
}

.datePicker {
  position: absolute !important;
  visibility: hidden !important;
}

.datePickerFooter {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 12px 0;
  background-color: #ffffff;

  .datePickerFooterBtn {
    width: 140px;
    background-color: $secondary;
    color: #ffffff;

    &:hover,
    &:active,
    &:focus {
      background-color: $secondary;
      color: #ffffff;
    }
  }
}

.selectDistributionIcon {
  position: absolute !important;
  right: 12px;
  top: 7px;
}

.tabs {
  border-bottom: 1px solid $light-blue;
  flex: 1;
  width: 100%;

  :global {
    .ant-tabs-nav {
      height: 56px !important;
    }

    .ant-tabs-nav::before {
      // border-bottom: none !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #242946 !important;
    }

    .ant-tabs-nav {
    }

    .ant-tabs-nav-operations {
      display: none !important;
    }

    .ant-tabs-tab {
      font-size: 16px !important;
      line-height: 24px !important;
      color: #242946 !important;
      transition: all 0.5s ease !important;
      justify-content: center !important;
      min-width: 75px !important;

      &:hover {
        color: $dark-nav;
      }
    }

    .ant-tabs-ink-bar {
      background: #0078ff !important;
      bottom: 8px !important;
      top: 54px !important;
    }
  }

  .tabPanel {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(36, 41, 70, 0.75);

    .plotViewsGraphic,
    .plotRevenueGraphic {
      width: 100%;
      // height: 100%;
    }
  }
}

.multipleApps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #242946;
  width: 158px;
}

.dateRangePickerWrapper {
  position: absolute;
  right: 0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;

  .dateRangePicker {
    position: relative;

    & > div:nth-child(1) {
      display: none !important;
    }

    & > div:nth-child(2) {
      & > div:nth-child(1) {
        display: none !important;
      }
    }
  }
}

:global {
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #ffffff !important;
  }
}

.monitizationLoader {
  position: relative !important;
  height: 300px !important;
  top: 50% !important;
  left: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 100 !important;
  color: $secondary !important;
  transform: translate(-50%, -50%) !important;
  margin-bottom: 5px !important;
}
