@import 'sources/styles/variables.scss';

.logOutModal {
  position: relative;
  border-radius: 6px;

  :global {
    .ant-modal-footer {
      .ant-btn-default {
        border-radius: 17px !important;
      }

      .ant-btn-primary {
        background-color: $secondary !important;
        border-radius: 17px !important;
        color: #ffffff !important;
      }
    }

    .ant-modal-content {
      height: 100% !important;
      border-radius: 6px !important;
    }

    .ant-modal-body {
      height: 100%;
      border-radius: 6px !important;
      padding-top: 30px !important;
      padding-bottom: 20px !important;
    }

    .ant-modal-header {
      border-radius: 6px !important;
    }
  }

  .text {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
  }
}
