@import 'sources/styles/variables.scss';

.upgrade {
  padding: 32px 16px;
  margin: 120px 24px 0;
  text-align: center;
  background: #ffffff;
  border: 1px solid $light-blue;
  border-radius: 10px;
  width: 200px;

  .upgradeText {
    max-width: 150px;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: $dark-nav;
  }

  .upgradeBtn {
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: $dark-nav;
    margin: 15px auto 0;
    border: 1px solid $light-blue;
    width: 110px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 1px solid $light-blue;
      color: $dark-nav;
    }
  }
}
