.category {
  position: relative;

  .name {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 8px;
    margin-left: 8px;
  }

  .list {
    list-style: none;
    display: flex;
    padding-left: 0;
    overflow-x: auto;
    margin-bottom: 34px;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #c8c8c8;
      border-radius: 22px;
    }

    &::-webkit-scrollbar-thumb {
      background: #0078ff;
      border-radius: 22px;
      max-width: 100px;
    }

    .mediaItem {
      max-width: 10px;
      border-radius: 10px;
      min-width: 216px;
      height: 148px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
      transition: transform 0.5s ease;
      cursor: pointer;
      margin-bottom: 25px;
      margin-top: 10px;
      margin-left: 16px;

      &:hover .mediaItemTitleWrapper {
        bottom: 0;
      }

      &:hover {
        transform: scale(1.1);
      }

      .mediaItemImg {
        width: 218px;
        height: 148px;
        transition: all 0.5s ease;
      }
    }

    .active {
      transform: scale(1.1);
      outline: 4px solid transparent;
    }
  }
}

.mediaItemTitleWrapper {
  position: absolute;
  bottom: -47px;
  left: 0;
  width: 100%;
  height: 47px;
  background: rgba(15, 15, 15, 0.2);
  color: #ffffff;
  backdrop-filter: blur(10px);
  transition: all 0.5s ease;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .title {
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #ffffff;
    margin: 4px 8px 0;
  }
}
.mediaItemDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 129px;
  padding-left: 8px;
}
