@import 'react-date-range/dist/styles.css'; // main css file
@import 'react-date-range/dist/theme/default.css'; // theme css file

@import 'antd/dist/antd.css';
@import './variables.scss';

html,
body,
#root {
  background: #ffffff;
  width: 100%;
  height: 100%;
}

body {
  font-family: $font;
  font-weight: normal;
  margin: 0;
}
