.userInfoWrapper {
  margin-left: auto;
  cursor: pointer;

  .name {
    font-weight: normal;
    font-size: 13px;
    color: rgba(36, 41, 70, 0.7);
    margin-right: 10px;
  }

  .avatar {
    width: 30px;
    height: 30px;
  }
}

.controlsOptionBtn {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  color: #000000 !important;
  padding: 0 8px !important;
  text-align: left !important;

  &:hover {
    color: #000000 !important;
  }
}
