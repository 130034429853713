@import 'sources/styles/variables.scss';
@import 'sources/styles/mixins.scss';

.mainWrapper {
  @include main-wrapper;

  .mainContent {
    display: flex;
    align-items: stretch;

    .section {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;

      .pageTitleWrapper {
        padding: 26px 24px 26px 34px;
        border-bottom: 1px solid $light-blue;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pageTitle {
          margin-bottom: 0;
          font-weight: 700;
          font-size: 28px;
          line-height: 32px;
          color: #001b39;
          flex: 1;
        }

        .titleBtn {
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
          color: $darken-gray;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 14px;
          height: 40px;

          .resetFiltersBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 12px;
          }

          & > svg {
            margin-right: 5px;
          }
        }

        .haveFilters {
          color: $secondary;

          & > svg path {
            stroke: $secondary;
          }
        }
      }
    }
  }
}

.table {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #000000 !important;
  text-align: center;
  padding-bottom: 50px;

  :global {
    .ant-table-selection,
    .ant-table-cell {
      .ant-checkbox-wrapper {
        position: relative;

        :global {
          .ant-checkbox:hover .ant-checkbox-inner {
            border-color: $primary !important;
          }

          .ant-checkbox-inner::after {
            left: 5px !important;
          }

          .ant-checkbox .ant-checkbox-inner {
            border-radius: 20px !important;
            width: 22px;
            height: 22px;
          }

          .ant-checkbox-checked .ant-checkbox-inner {
            border-radius: 10px !important;
            background-color: $primary;
            border-color: $primary;
          }

          .ant-checkbox-checked::after {
            border: none !important;
          }
        }
      }
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      display: none !important;
    }
  }

  .tableImg {
    height: 54px;
    width: 80px;
    border-radius: 10px;
  }

  .views {
    background: #ffffff;
    border: 1px solid #c1d1e3;
    border-radius: 70px;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #001b39;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 64px;

    svg {
      margin-left: 3px;
    }
  }

  .source {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    color: #000000;

    svg {
      margin-right: 10px;
    }
  }

  .tableSwitch {
    background: #fce8ed;
    width: 48px;
    height: 24px;
    opacity: 0.5;

    :global {
      .ant-switch-handle::before {
        background: $primary !important;
      }
    }
  }

  :global {
    .ant-switch-checked {
      opacity: 1 !important;
    }
  }
}

.tableAction {
  cursor: pointer;

  .tableActionLink a {
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    color: #000000;
  }
}

.bottomSelectedControls {
  min-width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff;
  border-top: 1px solid $light-blue;
  padding: 10px 12px;
  align-items: center;
  // max-width: 1440px;
  justify-content: flex-end;

  .selectedCloseBtn {
    border: none;
  }

  .selectedCount {
    margin: 0 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #090909;
  }

  .selectedBtn {
    margin-right: 20px;
    height: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    border: none;
    background: $primary;

    &:hover,
    &:focus {
      color: #ffffff;
      border: none;
      background: $primary;
    }
  }
}

.controlsActionsDropdown {
  border-radius: 10px !important;
  cursor: pointer !important;

  .controlsActionsDropdownLink {
    span a {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #000000 !important;
    }
  }
}

.controlsActionsBtn {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  color: #000000 !important;
  padding: 0 8px !important;
  text-align: left !important;

  &:hover {
    color: #000000 !important;
  }
}

.tableTVPreview {
  width: 18px;
  height: 18px;
}

.searchField {
  border: none !important;

  &::placeholder {
    color: $darken-gray !important;
  }

  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
}

.mediaItemStatus {
  position: absolute !important;
  top: 26px !important;
  left: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 100 !important;
  color: $media-item-loader !important;
}
