@import 'sources/styles/variables.scss';
@import 'sources/styles/mixins.scss';

.mainWrapper {
  @include main-wrapper;

  .mainContent {
    display: flex;

    .section {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;

      .pageTitleWrapper {
        padding: 26px 14px 26px 26px;
        border-bottom: 1px solid $light-blue;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 50px;
        left: 249px;
        width: calc(100% - 252px);
        z-index: 1000;
        background-color: #ffffff;

        .pageTitle {
          margin-bottom: 0;
          font-weight: 700;
          font-size: 28px;
          line-height: 32px;
          color: #001b39;
          flex: 1;
        }

        .titleBtn {
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
          color: $darken-gray;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          height: 40px;
          min-width: 94px;

          .resetFiltersBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 12px;
          }

          & > svg {
            margin-right: 5px;
          }
        }

        .exportBtn,
        .infoBtn {
          background: $secondary;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: #ffffff;
          height: 40px;
          min-width: 90px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
        }
      }
    }
  }
}

.table {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #000000 !important;
  text-align: center;
  padding-bottom: 50px;
  margin-top: 92px;

  .tableImg {
    height: 54px;
    width: 80px;
    border-radius: 10px;
  }

  .tableCategory {
    // text-decoration: underline;
  }

  .views {
    background: #ffffff;
    border: 1px solid #c1d1e3;
    border-radius: 70px;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #001b39;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 64px;

    svg {
      margin-left: 3px;
    }
  }
}

.selectDistributionWrapper {
  position: relative;
  top: 2px;

  .selectDistribution {
    :global {
      .ant-select-selector {
        border: 1px solid #e7f1fb !important;
        padding: 8px 22px 8px 14px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        min-width: 220px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: #242946 !important;
        cursor: pointer !important;
        border-radius: 20px !important;
        height: 40px !important;
        box-shadow: none !important;
      }

      .ant-select-selection-overflow {
        bottom: 3px !important;
      }

      .ant-select-selection-overflow-item .ant-select-selection-item {
        span {
          display: none !important;
          background-color: #ffffff !important;
        }
      }

      .ant-select-selection-item {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: #242946 !important;
        display: flex !important;
        background-color: #ffffff !important;
        border: none;

        div {
          display: none !important;
        }
      }
    }
  }
}

.distributionItem {
  background-color: #ffffff !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 10px !important;
  color: #000000 !important;

  :global {
    .ant-select-item-option-content {
      max-width: 150px !important;
    }

    div[aria-selected='true'] .selectedTimeImg {
      display: none !important;
      background-image: url('../../sources/images/dashboard/selected-distribution-item.svg') !important;
    }
  }
}

.selectTimeWrapper {
  margin-right: 10px;
  position: relative;
  top: 2px;

  .selectTime {
    :global {
      .ant-select-selector {
        border: 1px solid #e7f1fb !important;
        padding: 8px 22px 8px 14px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        min-width: 220px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: #242946 !important;
        cursor: pointer !important;
        border-radius: 20px !important;
        height: 40px !important;
        box-shadow: none !important;
      }

      .ant-select-selection-item {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: #242946 !important;
        display: flex !important;

        div {
          display: none !important;
        }
      }

      // .ant-select-item-option-active {
      //   background-color: #ffffff !important;
      // }
    }
  }
}

.timeItem {
  background-color: #ffffff !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 10px !important;
  color: #000000 !important;

  :global {
    .ant-select-item-option-content {
      max-width: 150px !important;
    }

    div[aria-selected='true'] .selectedTimeImg {
      display: none !important;
      background-image: url('../../sources/images/dashboard/selected-time-item.svg') !important;
    }
  }
}

.selectedTimeImg,
.selectedDistributionImg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 10px;
  background-image: url('../../sources/images/libary/unselected-category.svg');
  background-position: center;
  background-size: cover;
}

.customTimeBtn {
  border: none !important;
  display: flex !important;
  align-items: center !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #242946 !important;
  border: 1px solid #ffffff !important;
  width: 194px !important;
  box-shadow: 0px 1px 4px rgba(36, 41, 70, 0.15) !important;
  border-radius: 40px !important;
  // background-color: #f5f5f5 !important;

  span {
    margin-left: 8px !important;
    position: relative;
    top: 1px;
  }
}

.datePicker {
  position: absolute !important;
  visibility: hidden !important;
}

.datePickerFooter {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 12px 0;
  background-color: #ffffff;

  .datePickerFooterBtn {
    width: 140px;
    background-color: $secondary;
    color: #ffffff;

    &:hover,
    &:active,
    &:focus {
      background-color: $secondary;
      color: #ffffff;
    }
  }
}

.selectDistributionIcon {
  position: absolute !important;
  right: 12px;
  top: 7px;
}
