@import 'sources/styles/variables.scss';

.categoryWrapper {
  margin-bottom: 16px;
  position: relative;
  transition: all 5s ease;

  .dndIcon {
    position: absolute;
    top: 55%;
    cursor: grab;
  }

  .isCollapseDndIcon {
    top: 42%;
  }

  .categoryTopBar {
    display: flex;
    align-items: baseline;
    padding: 13px 14px 13px 7px;
    margin-left: 22px;

    .categoryName {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $dark-nav;
      margin-bottom: 0;
      margin-right: 14px;
      cursor: text;
    }

    .totalVideos,
    .totalViews {
      border: 1px solid $light-stroke;
      padding: 4px 10px;
      margin-right: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #4a4b4e;
      cursor: default;
    }

    .categoryControls {
      margin-left: auto;
      display: flex;
      align-items: center;

      .controlsArrow {
        transition: all 0.5s ease;
        margin-right: 16px;
      }

      svg {
        cursor: pointer;
      }

      svg:nth-child(1) {
        margin-right: 17px;
      }
    }
  }

  .collapseCategoryTopBar {
    border-bottom: 2px solid #0078ff;
    border-top: 2px solid #0078ff;
    margin-left: 22px;
  }

  .category {
    display: flex;
    align-items: center;

    .categorySlider {
      display: flex;
      align-items: center;
      overflow-x: auto;
      padding-left: 0;
      list-style: none;
      margin-left: 32px;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #c8c8c8;
        border-radius: 22px;
      }

      &::-webkit-scrollbar-thumb {
        background: #0078ff;
        border-radius: 22px;
        max-width: 100px;
      }

      .addVideoSlide {
        background: #f6fafe;
        border: 2px dashed #d1dbe5;
        border-radius: 4px;
        min-width: 216px;
        height: 148px;
        margin: 10px 8px 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        .addVideos {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: $dark-nav;
          cursor: pointer;

          .addVideosBtn {
            background: #ffffff;
            box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
            border: none;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;

            & > svg {
              path {
                fill: $primary;
              }
            }
          }
        }
      }

      .mediaItem {
        background: #f6fafe;
        border-radius: 10px;
        min-width: 216px;
        height: 148px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: grab;
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;

        &:hover .mediaItemControls {
          top: 0;
        }

        &:hover .mediaItemTitleWrapper {
          bottom: 0;
        }

        .mediaItemImg {
          width: 218px;
          height: 148px;
        }

        .mediaItemControls {
          position: absolute;
          top: -40px;
          left: 0;
          width: 100%;
          height: 40px;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: all 0.5s ease;

          .checkboxMediaItem {
            position: relative;

            :global {
              .ant-checkbox:hover .ant-checkbox-inner {
                border-color: $primary !important;
              }

              .ant-checkbox-inner::after {
                left: 5px !important;
              }

              .ant-checkbox .ant-checkbox-inner {
                border-radius: 20px !important;
                width: 22px;
                height: 22px;
              }

              .ant-checkbox-checked .ant-checkbox-inner {
                border-radius: 10px !important;
                background-color: $primary;
                border-color: $primary;
              }

              .ant-checkbox-checked::after {
                border: none !important;
              }
            }
          }
        }

        .mediaItemTitleWrapper {
          position: absolute;
          bottom: -47px;
          left: 0;
          width: 100%;
          height: 47px;
          background: rgba(15, 15, 15, 0.2);
          backdrop-filter: blur(10px);
          transition: all 0.5s ease;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          .title {
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
            color: #ffffff;
            margin: 4px 8px 0;
          }
        }
      }

      .draggingMediaItem img {
        background: #3b3d3e !important;
      }

      .selectedMediaItem {
        outline: 3px solid $primary;
      }
    }
  }
}

.controlsOptions {
  position: relative;
}

.mediaItemDropdown {
  border-radius: 10px !important;

  .mediaItemDropdownLink {
    span a {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #000000 !important;
    }
  }
}

.editableCategoryNameWrapper {
  display: flex;

  .editableCategoryName {
    width: 250px;
    margin-right: 10px;
    font-weight: 700;
  }

  .editBtn {
    margin-right: 8px;
    color: $secondary;
    background: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
  }

  .editBtn:last-child {
    margin-right: 45px;
  }
}

.controlsOptionBtn {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  color: #000000 !important;
  padding: 0 8px !important;
  text-align: left !important;

  &:hover {
    color: #000000 !important;
  }
}

.mediaItemStatus {
  position: absolute !important;
  height: 148px !important;
  top: -1px !important;
  left: 93px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 100 !important;
  color: $media-item-loader !important;
}

.togglePublishedCategory {
  margin-right: 18px;

  span {
    margin-right: 10px;
  }
}

.switchPublished {
  background-color: #ffffff !important;
  width: 48px;
  height: 24px;
  border: #e7f1fb;

  :global {
    .ant-switch-checked {
      background-color: #ffffff !important;
    }

    .ant-switch-handle::before {
      background: #79f185 !important;
    }
  }
}

.disabledPublished {
  border: #e7f1fb;

  :global {
    .ant-switch-checked {
      background-color: #ffffff !important;
    }

    .ant-switch-handle::before {
      background: #c6c8d2 !important;
    }
  }
}

.failedOverlay {
  position: absolute;
  min-width: 218px;
  height: 148px;
  background-color: rgba(255, 255, 255, 0.4);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .failedIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background: rgba(36, 41, 70, 0.7);
    border: 3px solid #f1557f;

    .failedIcon {
      color: #f1557f;
      font-weight: 900;
      font-size: 14px;
    }
  }
}
