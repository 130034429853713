@import 'sources/styles/variables.scss';

.selectApp {
  display: flex;
  align-items: center;
  max-width: 300px;
  border-left: 1px solid $light-blue;
  // margin-left: 132px;

  .icon {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $secondary;
    color: #ffffff;
    font-weight: 900;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 8px;
  }

  .select {
    width: 220px;
    background: $light-blue;
    border-radius: 4px;
    display: flex;
    align-items: center;

    div {
      & > span:nth-child(2) {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $dark-nav;
        top: 3px;
      }
    }

    span {
      top: 43%;
    }
  }
}

.titleBtn {
  height: 22px !important;
  background: inherit !important;
  color: #0078ff !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  // margin-right: 10px !important;
  padding: 0 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: $dark-nav !important;
  width: 100% !important;

  & > svg {
    margin-right: 0;
    border-radius: 15px;
    width: 24px;
    height: 20px;
    box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
    fill: #0078ff !important;
    position: relative;
    // right: 11px;
    background-color: #ffffff;

    path {
      fill: #0078ff !important;
      stroke: #0078ff !important;
    }
  }

  span {
    position: relative;
    left: 10px;
  }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #ffffff !important;
}

.addInputField {
  padding: 2px 6px !important;
  font-size: 12px !important;
  height: 24px !important;
  width: 160px !important;

  &:hover,
  &:focus {
    border: 1px solid #d9d9d9 !important;
    box-shadow: none !important;
  }
}

.addBtn {
  width: 32px !important;
  height: 31px !important;
  padding: 0 !important;
  background-color: $secondary !important;

  .addIcon {
    svg {
      fill: #ffffff !important;
    }
  }
}

.addLink {
  color: $secondary !important;
  transition: all 0.5s ease;
}

.space {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.closeBtn {
  cursor: pointer;

  svg {
    fill: #f1557f !important;
    font-size: 16px;
    position: relative;
    right: 2px;
    top: 3px;
  }
}

.doneBtn {
  cursor: pointer;

  svg {
    fill: #49c055 !important;
    font-size: 16px;
    position: relative;
    left: -3px;
    top: 2px;
  }
}
