@import 'sources/styles/variables.scss';
@import 'sources/styles/mixins.scss';

.mainWrapper {
  @include main-wrapper;

  .mainContent {
    display: flex;
    align-items: stretch;

    .section {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;

      .pageTitleWrapper {
        padding: 26px 24px 26px 34px;
        border-bottom: 1px solid $light-blue;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pageTitle {
          margin-bottom: 0;
          font-weight: 700;
          font-size: 28px;
          line-height: 32px;
          color: #001b39;
          flex: 1;
        }
      }

      .content {
        display: flex;

        .subtitle {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #242946;
          margin-bottom: 0;
        }

        .videoProperties {
          // width: 490px;
          padding: 32px;
          border-right: 1px solid $light-blue;
          background: radial-gradient(
            rgba(0, 120, 255, 0.08),
            rgba(255, 255, 255, 1)
          );
          width: 50%;

          .form {
            .formLabel {
              font-weight: 400;
              font-size: 12px;
              line-height: 100%;
              color: $dark-nav;
              margin-bottom: 8px;
            }

            .formItem {
              margin-top: 24px;

              label {
                font-weight: 400;
                font-size: 12px;
                line-height: 100%;
                color: $dark-nav;
                margin-bottom: 8px;
              }

              .formItemInput,
              .formItemTextArea,
              .formItemSelect {
                background: #ffffff;
                border: 1px solid #c6c8d2;
                border-radius: 8px;
                padding: 16px;
                color: #62636d;

                &:focus {
                  border-color: #c6c8d2 !important;
                  box-shadow: none;
                }

                &:hover {
                  border: 1px solid #c6c8d2;
                }

                &::placeholder {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 100%;
                  color: #aaacb9;
                }
              }

              .formItemTextArea {
                resize: none;
                height: 164px;
              }

              .formItemSelect {
                padding: 7px 16px;

                :global {
                  .ant-select-selector {
                    border: none;
                    background-color: #fff;
                  }

                  .ant-select-selection-overflow-item {
                    span {
                      background: $secondary;
                      color: #ffffff;
                      border-radius: 50px;
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 32px;
                      padding: 0 4px;
                    }
                  }
                }

                .selectTagsOption {
                  position: relative;

                  :global {
                    .ant-select-item-option-state {
                      display: none !important;
                    }
                  }

                  div {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 10px;
                    color: #000000;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;

                    .switch {
                      position: absolute !important;
                      right: 0 !important;
                    }
                  }
                }
              }
            }

            .thumnail {
              margin-top: 35px;

              .uploadWrapper {
                background: #ffffff;
                border-radius: 10px;
                width: 313px;
                height: 191px;
                border: none;
                background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.4),
                    rgba(0, 0, 0, 0.4)
                  ),
                  url('../../sources/images/libary/video-properties.png');

                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;

                .text {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 140%;
                  text-align: center;
                  color: #ffffff;
                  max-width: 120px;
                  margin: 16px auto;
                }

                .uploadBtn {
                  background: $secondary;
                  border: none;
                  width: 136px;
                  height: 30px;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 14px;
                  display: flex;
                  align-items: center;
                  color: #ffffff;
                  margin: 0 auto;

                  span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }

        .videoInfo {
          flex: 1;
          padding: 32px;
          background: radial-gradient(
            rgba(200, 85, 241, 0.08),
            rgba(255, 255, 255, 1)
          );
          display: flex;
          flex-direction: column;
          width: 50%;

          .videoInfoThumbnail {
            margin: 46px 0;
            height: 377px;
            width: 566px;
            position: relative;
            border-radius: 10px;

            .videoInfoThumbnailImg {
              width: 100%;
              height: 100%;
            }

            .selectThumbnailBtn {
              position: absolute;
              left: 20px;
              top: 20px;
              background: $secondary;
              color: #ffffff;
              height: 31px;
              font-weight: 700;
              font-size: 14px;
              line-height: 14px;
              border-color: $secondary;
            }

            .thumbnailbBottom {
              position: absolute;
              bottom: 0;
              left: 0;
              background: rgba(15, 15, 15, 0.2);
              color: #ffffff;
              display: flex;
              align-items: center;
              padding: 11px 16px;
              width: 100%;

              .bottomText {
                font-weight: 500;
                font-size: 11px;
                line-height: 14px;
                color: #ffffff;
                margin-left: 10px;
              }

              .volumeIcon {
                margin-left: auto;
              }
            }
          }

          .info {
            display: grid;
            grid-template-columns: 275px 275px;
            grid-gap: 16px;
            margin-top: 34px;

            .infoItem {
              background: #ffffff;
              border-radius: 10px;
              padding: 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .infoItemText {
                font-weight: 400;
                font-size: 12px;
                line-height: 100%;
                color: $dark-nav;
              }

              .infoItemValue {
                background: #ffffff;
                border: 1px solid $light-blue;
                font-weight: 400;
                font-size: 12px;
                line-height: 100%;
                color: $darken-gray;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: default;

                svg {
                  position: relative;
                  top: 3px;
                  right: 8px;
                }

                .infoItemValuelink {
                  text-decoration: underline;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #000000;
                  margin-left: 4px;
                }
              }

              .source {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

.dropdownThumbnail {
  width: 254px;
  // height: 284px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(36, 41, 70, 0.15);
  border-radius: 10px;
  padding: 10px 24px 0;

  .dropdownText {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: $dark-nav;
    max-width: 102px;
    margin-bottom: 8px;
  }

  .dropdownUpload {
    border: 1px solid $secondary !important;
    border-radius: 10px !important;
    width: 206px !important;
    height: 125px !important;
    padding: 0 !important;
    background: url('../../sources/images/libary/video-properties.png') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin: 16px 0 10px;
  }

  .dropdownBtn {
    background: #ffffff;
    border: 1px solid $light-blue;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: $dark-nav;
    width: 100%;
    height: 31px;
    margin-bottom: 8px;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid $light-blue;
      color: $dark-nav;
    }

    span {
      margin: 0 auto;
    }
  }
}

.dropdownWrapper {
  :global {
    .ant-dropdown-menu-item {
      padding: 0;
    }
  }
}

.spinner {
  position: relative !important;
  top: 38% !important;

  :global {
    .ant-spin-dot {
      top: 46% !important;
      position: fixed !important;
    }
  }
}

.controlsBtnWrapper {
  margin: 0;

  .controlsBtn {
    margin-right: 12px;
    background: $secondary;
    color: #ffffff;
    border: none;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 80px;

    &:hover,
    &:active,
    &:focus {
      background: $secondary !important;
      color: #ffffff !important;
    }
  }
}

.tableTVPreview {
  width: 18px;
  height: 18px;
}

.fileName {
  position: relative;
  top: 3px;
  font-size: 16px;
  font-weight: 700;
}

#uploadImg {
  width: 313px;
  height: 191px;
  position: absolute;
}

.videoPlayer {
  position: relative;

  video {
    border-radius: 12px !important;
    object-fit: fill !important;
  }
}

.imageFrame {
  border-radius: 10px;
  width: 206px;
  height: 125px;
  outline: 2px solid $secondary;
  border-radius: 10px;
}

.firstBtn {
  margin-top: 10px;
}

.secondBtn {
  margin-bottom: 0;
}

.hiddenBtn {
  visibility: hidden;
}

.backArrowLeft {
  border: none !important;
  margin-right: 5px;
}

.categoryItem {
  position: relative;

  :global {
    .ant-select-item-option-content {
      max-width: 150px !important;
    }

    div[aria-selected='true'] .selectedCategoryIcon {
      background-image: url('../../sources/images/videoProperties/selected-category.svg') !important;
    }
  }
}

.selectedCategoryIcon {
  width: 38px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 10px;
  background-image: url('../../sources/images/videoProperties/unselected-category.svg');
  background-position: center;
  background-size: cover;
}

.formItemSelect {
  :global {
    .ant-select-selection-item-content {
      div {
        display: none !important;
      }
    }
  }
}
