@import 'sources/styles/variables.scss';

.wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 237px;
  margin-right: 16px;
  z-index: 10;
  position: relative;

  .value {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 4px;
  }

  .title {
    color: #62636d;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 7px;
  }

  .percentWrapper {
    display: flex;
    align-items: center;

    .percent {
      color: #242946;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      margin-right: 8px;
    }
  }
}

.isLoading {
  padding-top: 50px !important;
}

.monitizationLoader {
  position: absolute !important;
  // height: 148px !important;
  top: 30% !important;
  left: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 100 !important;
  color: $secondary !important;
  transform: translate(-50%, -50%) !important;
}
