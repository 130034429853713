@import 'sources/styles/variables.scss';

.addVideosTopBar {
  // max-width: 1440px;
  padding: 6px 16px 6px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 100;
  min-height: 92px;
  border-bottom: 1px solid $light-blue;
  position: sticky;
  left: 0;
  top: 0;
  background-color: #ffffff;

  .backBtn {
    width: 44px;
    height: 44px;
    background: $secondary;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    border: none;

    &:hover {
      background: $secondary;
    }
  }

  .title {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #001b39;
    margin-bottom: 0;
  }

  .saveVideosWrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .saveVideosText {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #090909;
    }

    .saveVideosBtn {
      margin-left: 16px;
      background: #c1d1e3;
      border-radius: 56px;
      color: #ffffff;
      border: none;
    }

    .active {
      background: $secondary;
    }
  }
}
