@import 'sources/styles/variables.scss';

.sideBarWrapper {
  min-width: 260px;
  display: flex;
  flex-direction: column;
  padding: 0 0 50px;
  border-right: 1px solid $light-blue;
  position: sticky;
  left: 0;
  top: 92px;
  // height: 100%;

  .topText {
    margin-top: 37px;
    margin-left: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #001b39;
    max-width: 176px;
  }

  .menu {
    border: none;
    padding-top: 35px !important;

    .subMenuItem {
      padding: 0;

      &:hover a {
        color: $primary;
      }

      :global {
        .ant-menu-submenu-arrow {
          display: none;
        }
      }

      a {
        color: rgba(0, 0, 0, 0.85);
        transition: all 0.5s ease;
      }
    }

    .menuItem {
      display: flex;
      align-items: center;
      padding: 0 20px;
      transition: all 0.5s ease;

      svg > path {
        transition: all 0.5s ease;
      }

      &:hover svg > path {
        stroke: $primary;
      }

      &:hover {
        border-left: 3px solid $primary;
      }

      .menuLink {
        transition: all 0.5s ease;
        margin-bottom: 18px;

        &:hover {
          color: $primary;
        }
      }
    }

    .computer,
    .vimeo {
      svg > path {
        transition: all 0.5s ease;
      }

      &:hover svg > path {
        fill: $primary;
      }
    }

    .youtube {
      svg > path:nth-child(1) {
        transition: all 0.5s ease;
      }

      &:hover svg > path:nth-child(1) {
        fill: $primary;
      }
    }

    .activeLink {
      border-left: 3px solid $primary;
      transition: all 0.5s ease;

      .menuLink {
        color: $primary;
      }

      svg > path {
        stroke: $primary;
      }
    }

    .activeLink.computer,
    .activeLink.vimeo {
      svg > path {
        transition: all 0.5s ease;
        fill: $primary;
      }
    }

    .activeLink.youtube {
      svg > path:nth-child(1) {
        transition: all 0.5s ease;
        fill: $primary;
      }
    }
  }
}

.account {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 200px;

  .accountAvatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15px;
  }

  .accountEmail {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $dark-nav;
    transition: all 0.5s ease;
  }
}

.addAccount {
  min-width: 200px;

  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .titleBtn {
      background: #ffffff;
      color: #0078ff;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      padding: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $dark-nav;
      width: 140px;

      & > svg {
        margin-right: 0;
        border-radius: 15px;
        width: 30px;
        box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
        fill: #0078ff !important;
        position: relative;
        right: 11px;

        path {
          fill: #0078ff !important;
          stroke: #0078ff !important;
        }
      }

      span {
        position: relative;
      }
    }
  }
}

:global {
  .ant-menu-inline .ant-menu-item::after {
    display: none !important;
  }
}
