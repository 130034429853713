@import 'sources/styles/variables.scss';

.topBar {
  // max-width: 1440px;
  padding: 6px 16px 6px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1000;
  min-height: 50px;
  position: sticky;
  left: 0;
  top: 0;
  background-color: #ffffff;
  border-bottom: 1px solid $light-blue;

  .logo {
    height: 16px;
    margin-right: 135px;
  }
}

.backBtn {
  width: 34px !important;
  height: 34px !important;
  background: $secondary !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 16px !important;
  border: none !important;
  margin-left: 30px !important;

  &:hover {
    background: $secondary !important;
  }
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #001b39;
  margin-bottom: 0;
}
