@import 'sources/styles/variables.scss';
@import 'sources/styles/mixins.scss';

.mainWrapper {
  @include main-wrapper;

  .mainContent {
    display: flex;
    align-items: stretch;

    .section {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;

      .pageTitleWrapper {
        padding: 26px 24px 26px 34px;
        border-bottom: 1px solid $light-blue;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pageTitle {
          margin-bottom: 0;
          font-weight: 700;
          font-size: 28px;
          line-height: 32px;
          color: #001b39;
          flex: 1;
        }

        .titleBtn {
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
          color: $secondary;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 14px;
          height: 40px;

          & > svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.content {
  flex: 1;
  padding: 0 30px 80px;

  .tabs {
    border-bottom: 1px solid $light-blue;

    :global {
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $dark-nav;
        text-align: center;
        height: 3px !important;
      }

      .ant-tabs-tab {
        align-items: baseline !important;

        &:hover {
          color: $dark-nav;
        }
      }

      .ant-tabs-ink-bar {
        background: $primary;
      }
    }

    .tabPaneGuid {
      display: flex;
      justify-content: space-between;
      min-height: 100%;

      .guidesWrapper {
        padding: 0 32px 32px 0;
        width: 45%;
        background: radial-gradient(
          rgba(0, 120, 255, 0.08),
          rgba(255, 255, 255, 1)
        );

        .guidTitle {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $dark-nav;
          margin-bottom: 16px;
        }

        .guidLink {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: $secondary;
          margin-bottom: 24px;
          display: block;
          max-width: 100%;
          text-decoration: underline;
        }

        .guidText {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: $dark-nav;
          max-width: 420px;
          margin-bottom: 24px;
        }
      }

      .uploadSectionWrapper {
        display: flex;
        flex-direction: column;
        width: 55%;
        background: radial-gradient(
          rgba(200, 85, 241, 0.08),
          rgba(255, 255, 255, 1)
        );

        .uploadSplashSection {
        }
      }
    }
  }
}

.spinner {
  position: absolute !important;
  left: 55%;
  top: 50%;
}

.builderBtn {
  background-color: $secondary !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  color: #ffffff !important;
  margin-right: 12px !important;
  height: 32px !important;
  border: 1px solid $secondary !important;
  max-width: 155px !important;

  &:hover,
  &:active,
  &:focus {
    background-color: $secondary !important;
    color: #ffffff !important;
    border: 1px solid $secondary !important;
  }

  & > span {
    height: auto !important;
    position: relative !important;
    top: 1.5px !important;
  }
}

.disabled {
  background: #c1d1e3 !important;
  color: #ffffff !important;
  border: none !important;

  &:hover,
  &:active,
  &:focus {
    background: #c1d1e3 !important;
    color: #ffffff !important;
    border: none !important;
  }
}
