@import 'sources/styles/variables.scss';

.item {
  background: #f6fafe;
  border: 2px dashed #d1dbe5;
  border-radius: 4px;
  min-width: 216px;
  height: 148px;
  margin: 10px 8px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
