.popularColors {
  list-style: none;
  padding-left: 0;
  display: flex;

  .circle {
    width: 21px;
    height: 21px;
    background-color: #000000;
    border-radius: 10px;
    margin-right: 8px;
    transition: all 0.5s ease;
    cursor: pointer;
  }

  .active {
    transform: scale(1.25);
  }
}

.canvas {
  min-height: 127px;
  width: 248px;
  border-radius: 8px;
  cursor: pointer;
}

.colorPicker {
  position: relative;
  border-radius: 6px;

  // div:last-child {
  //   div:first-child {
  //     div:last-child {
  //       div:last-child {
  //         display: none;
  //       }
  //     }
  //   }
  // }
}
