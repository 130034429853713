@import 'sources/styles/variables.scss';

.tabs {
  border-bottom: 1px solid $light-blue;
  flex: 1;
  width: 100%;
  padding: 0 24px !important;
  overflow-y: auto !important;
  // max-height: 69vh;
  // height: 69vh;
  max-width: 80vw;

  @media screen and (min-height: 700px) {
    max-height: 52vh;
    height: 52vh;
  }

  @media screen and (min-height: 800px) {
    max-height: 59vh;
    height: 59vh;
  }

  @media screen and (min-height: 900px) {
    max-height: 63vh;
    height: 63vh;
  }

  @media screen and (min-height: 1000px) {
    max-height: 66vh;
    height: 66vh;
  }

  @media screen and (min-height: 1100px) {
    max-height: 69vh;
    height: 69vh;
  }

  @media screen and (min-height: 1200px) {
    max-height: 72vh;
    height: 72vh;
  }

  @media screen and (min-height: 1300px) {
    max-height: 75vh;
    height: 75vh;
  }

  @media screen and (min-height: 1400px) {
    max-height: 78vh;
    height: 78vh;
  }

  @media screen and (min-height: 1500px) {
    max-height: 81vh;
    height: 81vh;
  }

  @media screen and (min-height: 1600px) {
    max-height: 84vh;
    height: 84vh;
  }

  @media screen and (min-height: 1700px) {
    max-height: 87vh;
    height: 87vh;
  }

  @media screen and (min-height: 1800px) {
    max-height: 90vh;
    height: 90vh;
  }

  @media screen and (min-height: 1900px) {
    max-height: 93vh;
    height: 93vh;
  }

  @media screen and (min-height: 2000px) {
    max-height: 96vh;
    height: 96vh;
  }

  @media screen and (min-height: 2100px) {
    max-height: 99vh;
    height: 99vh;
  }

  @media screen and (min-width: 1441px) {
    max-width: 80vw;
  }

  @media screen and (min-width: 1800px) {
    max-width: 83vw;
  }

  @media screen and (min-width: 2000px) {
    max-width: 85vw;
  }

  // @media screen and (min-width: 2000px) {
  //   max-height: 1000px;
  //   height: 1000px;
  // }

  // @media screen and (min-width: 3000px) {
  //   max-height: 1400px;
  //   height: 1400px;
  // }

  :global {
    .ant-tabs-nav {
      // position: sticky !important;
      // top: 0 !important;
      // left: 0 !important;
      height: 56px !important;
      // z-index: 1000 !important;
      // background-color: #ffffff !important;
    }

    .ant-tabs-nav::before {
      border-bottom: none !important;
    }
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #c8c8c8;
    border-radius: 22px;
  }

  &::-webkit-scrollbar-thumb {
    background: #0078ff;
    border-radius: 22px;
    max-width: 10px;
  }

  .tabOperations {
    display: flex;

    .uploadBtn {
      background-color: $secondary;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-right: 12px;
      height: 42px;

      & > span {
        height: auto !important;
      }
    }

    .tabOperationsBtn {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: $darken-gray;
      height: 42px;
      background: #ffffff;
      border: 1px solid $light-blue;
      margin-right: 12px;

      svg {
        margin: 0 7px;
      }

      & > span {
        height: auto !important;
      }

      & > label {
        height: 22px;
        display: flex;
        flex-direction: row-reverse;
        align-items: baseline;
        position: relative;
        bottom: 3px;

        span:nth-child(2) {
          position: relative;
          bottom: 3px;
        }
      }
    }
  }

  .tabVideos,
  .tabPlaylists {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(36, 41, 70, 0.75);
  }

  :global {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $dark-nav;
    }

    .ant-tabs-nav {
      padding-left: 26px !important;
    }

    .ant-tabs-nav-operations {
      display: none !important;
    }

    .ant-tabs-tab {
      &:hover {
        color: $dark-nav;
      }
    }

    .ant-tabs-ink-bar {
      background: $primary;
      bottom: 8px !important;
    }
  }

  .tabAssets {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(36, 41, 70, 0.75) !important;
    display: flex;
    flex-direction: column;

    & > span {
      margin-bottom: 16px;
    }
  }

  .tabColors {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(36, 41, 70, 0.75) !important;
  }
}

.checkbox {
  position: relative;

  :global {
    .ant-checkbox:hover,
    .ant-checkbox:hover:focus {
      border-color: $primary !important;
    }

    .ant-checkbox-inner {
      border-color: #c6c8d2 !important;
    }

    .ant-checkbox-inner::after {
      left: 5px !important;
    }

    .ant-checkbox .ant-checkbox-inner {
      border-radius: 20px !important;
      width: 22px;
      height: 22px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border-radius: 10px !important;
      background-color: $primary;
      border-color: $primary;
    }

    .ant-checkbox-checked::after {
      border: none !important;
    }
  }
}

.searchField {
  border: none !important;

  &::placeholder {
    color: $darken-gray !important;
  }

  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
}
