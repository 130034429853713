@import 'sources/styles/variables.scss';
@import 'sources/styles/mixins.scss';

.mainWrapper {
  @include main-wrapper;

  .mainContent {
    display: flex;
    align-items: stretch;

    .section {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      height: fit-content;

      .pageTitleWrapper {
        padding: 26px 24px 26px 34px;
        border-bottom: 1px solid $light-blue;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        justify-content: space-between;
        position: fixed;
        top: 50px;
        left: 249px;
        width: calc(100% - 252px);
        z-index: 1000;

        .pageTitle {
          margin-bottom: 0;
          font-weight: 700;
          font-size: 28px;
          line-height: 32px;
          color: #001b39;
          flex: 1;
        }

        .titleBtn {
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
          color: $secondary;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 14px;
          height: 40px;

          & > svg {
            margin-right: 5px;
          }
        }
      }

      .content {
        padding: 116px 32px 0 16px;

        flex: 1;

        .createNewCategory {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
          text-align: center;
          color: #5395ea;
          cursor: pointer;
          margin-bottom: 40px;
          position: relative;
          bottom: 9px;

          .createNewCategoryBtn {
            background: #ffffff;
            border: none;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 14px;
              line-height: 26px;
              text-align: center;
              color: #5395ea;
              cursor: pointer;
            }

            & > svg {
              height: 26px;
              width: 26px;
              box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
              border-radius: 30px;
              margin-right: 8px;
              font-size: 24px;

              path {
                fill: #0078ff;
              }
            }
          }
        }
      }
    }
  }
}

.bottomSelectedControls {
  min-width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff;
  border-top: 1px solid $light-blue;
  padding: 10px 12px;
  align-items: center;
  // max-width: 1440px;

  .selectedCloseBtn {
    border: none;
  }

  .selectedCount {
    margin: 0 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #090909;
  }

  .selectedBtn {
    margin-right: 16px;
    height: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    border: none;
    background: $primary;

    &:hover,
    &:focus {
      color: #ffffff;
      border: none;
      background: $primary;
    }
  }
}

.spinner {
  position: absolute !important;
  top: 50%;
  left: 55%;

  .ant-spin-dot {
    top: 46% !important;
    position: fixed !important;
  }
}
