@import 'sources/styles/variables.scss';

.categoryWrapper {
  margin-bottom: 16px;
  position: relative;
  min-height: 250px;

  .categoryTopBar {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 13px 14px 13px 10px;
    margin-left: 22px;

    .categoryName {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $dark-nav;
      margin-bottom: 0;
      margin-right: 14px;
      line-height: 45px;
    }
  }

  .category {
    display: flex;
    align-items: center;
    // height: 200px;

    .categorySlider {
      display: flex;
      align-items: center;
      overflow-x: auto;
      padding-left: 0;
      list-style: none;
      margin-left: 32px;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #c8c8c8;
        border-radius: 22px;
      }

      &::-webkit-scrollbar-thumb {
        background: #0078ff;
        border-radius: 22px;
        max-width: 100px;
      }

      .addVideoSlide {
        background: #f6fafe;
        border: 2px dashed #d1dbe5;
        border-radius: 4px;
        min-width: 216px;
        height: 148px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        .addVideos {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: $dark-nav;
          cursor: pointer;

          .addVideosBtn {
            background: #ffffff;
            box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
            border: none;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;

            & > svg {
              path {
                fill: $primary;
              }
            }
          }
        }
      }

      .mediaItem {
        background: #f6fafe;
        border-radius: 10px;
        min-width: 216px;
        max-width: 216px;
        height: 148px;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: grab;
        position: relative;
        overflow: hidden;

        &:hover .mediaItemControls {
          top: 0;
        }

        &:hover .mediaItemTitleWrapper {
          bottom: 0;
        }

        .mediaItemImg {
          width: 218px;
          height: 148px;
        }

        .mediaItemControls {
          position: absolute;
          top: -40px;
          left: 0;
          width: 100%;
          height: 40px;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: all 0.5s ease;

          .checkboxMediaItem {
            position: relative;

            :global {
              .ant-checkbox:hover .ant-checkbox-inner {
                border-color: $primary !important;
              }

              .ant-checkbox-inner::after {
                left: 5px !important;
              }

              .ant-checkbox .ant-checkbox-inner {
                border-radius: 20px !important;
                width: 22px;
                height: 22px;
              }

              .ant-checkbox-checked .ant-checkbox-inner {
                border-radius: 10px !important;
                background-color: $primary;
                border-color: $primary;
              }

              .ant-checkbox-checked::after {
                border: none !important;
              }
            }
          }
        }

        .mediaItemTitleWrapper {
          position: absolute;
          bottom: -47px;
          left: 0;
          width: 100%;
          height: 47px;
          background: rgba(15, 15, 15, 0.2);
          backdrop-filter: blur(10px);
          transition: all 0.5s ease;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          .title {
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
            color: #ffffff;
            margin: 4px 8px 0;
          }
        }

        .nameWithoutImg {
          position: absolute;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          bottom: 32px;
          text-align: center;
        }
      }

      .selectedMediaItem {
        outline: 3px solid $primary;
      }
    }
  }
}

.selectedVideos {
  display: flex;
  align-items: center;
  margin-right: 28px;

  .selectedVideosText {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: $dark-nav;
    margin-right: 13px;
  }

  .selectedVideosAddBtn {
    height: 30px;
    background: $primary;
    color: #ffffff;
    border: none;
    margin-right: 12px;

    &:hover {
      background: $primary;
      color: #ffffff;
    }

    &:active,
    &:focus {
      background: $primary;
      color: #ffffff;
    }
  }

  .disabledSelected {
    background: #c1d1e3;
    color: #ffffff;
  }

  .selectedVideosResetBtn {
    border: none;
  }
}

.addedMediaItem {
  background: #d1dbe5;
  border-radius: 10px;
  min-width: 216px;
  height: 148px;
  margin: 10px 8px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  z-index: 10;

  &:hover .mediaItemControls {
    top: 0;
  }

  &:hover .mediaItemTitleWrapper {
    bottom: 0;
  }

  .mediaItemControls {
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 40px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.5s ease;

    .checkboxMediaItem {
      position: relative;

      :global {
        .ant-checkbox:hover .ant-checkbox-inner {
          border-color: $primary !important;
        }

        .ant-checkbox-inner::after {
          left: 5px !important;
        }

        .ant-checkbox .ant-checkbox-inner {
          border-radius: 20px !important;
          width: 22px;
          height: 22px;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          border-radius: 10px !important;
          background-color: $primary;
          border-color: $primary;
        }

        .ant-checkbox-checked::after {
          border: none !important;
        }
      }
    }
  }

  .mediaItemTitleWrapper {
    position: absolute;
    bottom: -47px;
    left: 0;
    width: 100%;
    height: 47px;
    background: rgba(15, 15, 15, 0.2);
    backdrop-filter: blur(10px);
    transition: all 0.5s ease;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .title {
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: #ffffff;
      margin: 4px 8px 0;
    }
  }
}

.removeBtn {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  color: #000000 !important;
  padding: 0 8px !important;

  &:hover {
    color: #000000 !important;
  }
}

.failedOverlay {
  position: absolute;
  min-width: 218px;
  height: 148px;
  background-color: rgba(255, 255, 255, 0.4);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .failedIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background: rgba(36, 41, 70, 0.7);
    border: 3px solid #f1557f;

    .failedIcon {
      color: #f1557f;
      font-weight: 900;
      font-size: 14px;
    }
  }
}
