@import 'sources/styles/variables.scss';

.mainWrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  // max-width: 1440px;
  margin: 0 auto;
  // border-bottom: 1px solid $light-blue;

  .mainContentWithForm {
    position: relative;

    @media screen and (min-height: 700px) {
      max-height: none !important;
      height: inherit !important;
    }

    @media screen and (min-height: 800px) {
      max-height: none !important;
      height: inherit !important;
    }

    @media screen and (min-height: 900px) {
      max-height: none !important;
      height: inherit !important;
    }
  }

  .mainContent {
    display: flex;
    align-items: stretch;
    flex: 1;

    @media screen and (min-height: 700px) {
      max-height: 53vh;
      height: 53vh;
    }

    @media screen and (min-height: 800px) {
      max-height: 59vh;
      height: 59vh;
    }

    @media screen and (min-height: 900px) {
      max-height: 63vh;
      height: 63vh;
    }

    @media screen and (min-height: 1000px) {
      max-height: 66vh;
      height: 66vh;
    }

    @media screen and (min-height: 1100px) {
      max-height: 69vh;
      height: 69vh;
    }

    @media screen and (min-height: 1200px) {
      max-height: 72vh;
      height: 72vh;
    }

    @media screen and (min-height: 1300px) {
      max-height: 75vh;
      height: 75vh;
    }

    @media screen and (min-height: 1400px) {
      max-height: 78vh;
      height: 78vh;
    }

    @media screen and (min-height: 1500px) {
      max-height: 81vh;
      height: 81vh;
    }

    @media screen and (min-height: 1600px) {
      max-height: 84vh;
      height: 84vh;
    }

    @media screen and (min-height: 1700px) {
      max-height: 87vh;
      height: 87vh;
    }

    @media screen and (min-height: 1800px) {
      max-height: 90vh;
      height: 90vh;
    }

    @media screen and (min-height: 1900px) {
      max-height: 93vh;
      height: 93vh;
    }

    @media screen and (min-height: 2000px) {
      max-height: 96vh;
      height: 96vh;
    }

    @media screen and (min-height: 2100px) {
      max-height: 99vh;
      height: 99vh;
    }

    // @media screen and (min-width: 1441px) {
    //   max-width: 80vw;
    // }

    // @media screen and (min-width: 1800px) {
    //   max-width: 83vw;
    // }

    // @media screen and (min-width: 2000px) {
    //   max-width: 85vw;
    // }

    .section {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      align-items: center;
      justify-content: center;
      // min-height: 660px;
      padding: 67px 0 0;

      // @media screen and (min-height: 720px) and (max-height: 900px) {
      //   padding: 12px 0 12px;
      //   height: 445px;
      //   width: 75%;
      // }
    }
  }
}

.spinner {
  position: relative !important;
  top: 44% !important;
  left: 46%;
}

.spinnerVimeoData {
  position: relative !important;
  top: 44% !important;
  left: 38%;

  :global {
    .ant-spin-dot {
      top: 46% !important;
    }
  }
}

.tempMediaItem {
  background: linear-gradient(
      0deg,
      rgba(0, 27, 57, 0.33),
      rgba(0, 27, 57, 0.33)
    ),
    #f6fafe;
  border: 2px dashed #d1dbe5;
  border-radius: 10px;
  min-width: 216px;
  max-width: 216px;
  height: 148px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  position: relative;
  overflow: hidden;
}
