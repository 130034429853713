@import 'sources/styles/variables.scss';
@import 'sources/styles/mixins.scss';

.mainWrapper {
  @include main-wrapper;

  .mainContent {
    display: flex;
    align-items: stretch;

    .section {
      width: 100%;
      display: flex;
      flex-direction: column;

      .pageTitleWrapper {
        padding: 26px 24px 24px 34px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pageTitle {
          margin-bottom: 0;
          font-weight: 700;
          font-size: 28px;
          line-height: 32px;
          color: #001b39;
          flex: 1;
        }

        .titleBtn {
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
          color: $secondary;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 14px;
          height: 40px;

          & > svg {
            margin-right: 5px;
          }
        }
      }

      .content {
        flex: 1;
        padding: 0 30px 80px;

        .tabs {
          border-bottom: 1px solid $light-blue;

          :global {
            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
              color: $dark-nav;
            }

            .ant-tabs-tab {
              &:hover {
                color: $dark-nav;
              }
            }

            .ant-tabs-ink-bar {
              background: $primary;
            }
          }

          .tabAssets {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: rgba(36, 41, 70, 0.75) !important;
            display: flex;
            flex-direction: column;

            & > span {
              margin-bottom: 16px;
            }
          }

          .tabColors {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: rgba(36, 41, 70, 0.75) !important;
          }
        }
      }
    }
  }
}

.spinner {
  position: absolute !important;
  left: 55%;
  top: 50%;
}
