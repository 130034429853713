@import 'sources/styles/variables.scss';

.mainWrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  // max-width: 1440px;
  margin: 0 auto;
  // border-bottom: 1px solid $light-blue;

  .mainContent {
    display: flex;
    align-items: stretch;
    flex: 1;

    .section {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      padding: 0 30px;
      // min-height: 660px;
      // align-items: center;
      // justify-content: center;

      .tabs {
        border-bottom: 1px solid $light-blue;
        min-height: 100%;

        :global {
          .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: $dark-nav;
            text-align: center;
            height: 3px !important;
          }

          .ant-tabs-content {
            min-height: 100%;
          }

          .ant-tabs-tabpane {
            margin: auto;
          }

          .ant-tabs-tab {
            align-items: baseline !important;
            width: 50px !important;
            justify-content: center !important;

            &:hover {
              color: $dark-nav;
            }
          }

          .ant-tabs-ink-bar {
            background: $primary;
          }
        }
      }

      .formUrl {
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        max-width: 560px;
        padding: 28px 58px !important;
        margin: auto;

        .title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            border: 1px solid #efefef;
            cursor: default;
          }

          .text {
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: $dark-nav;
            margin-left: 18px;
          }
        }

        .formItem {
          :global {
            .ant-form-item-explain-error {
              position: relative !important;
              top: -8px !important;
            }
          }

          .formInput {
            border: 1px solid $light-blue;
            border-radius: 4px;
            margin-bottom: 16px;
            padding: 7px 15px;

            input {
              font-weight: normal;
              font-size: 14px;
              line-height: 29px;
              color: $dark-nav;
            }

            &::placeholder {
              font-weight: 400 !important;
              font-size: 14px !important;
              line-height: 29px !important;
              color: #000000 !important;
            }
          }
        }

        .helpWrapper {
          display: flex;
          align-items: center;
          margin-bottom: 24px;

          .helpIcon {
            margin-right: 13px;
          }

          .helpLink {
            text-decoration: underline;
            font-weight: 600;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            text-decoration-line: underline;
            color: $dark-nav;
          }
        }

        .submitBtn {
          background: $primary;
          color: #ffffff;
          width: 100%;
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
          height: 38px;
          border: none;

          &:hover {
            background: $primary;
            color: #ffffff;
          }
        }
      }

      .formRss {
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        max-width: 560px;
        padding: 38px 66px !important;
        margin: auto;

        .title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 48px;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            border: 1px solid #efefef;
            cursor: default;
          }

          .text {
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: $dark-nav;
            margin-left: 18px;
          }
        }

        .formItem {
          .formInput {
            border: 1px solid $light-blue;
            border-radius: 4px;
            margin-bottom: 16px;
            padding: 7px 15px;

            input {
              font-weight: normal;
              font-size: 14px;
              line-height: 29px;
              color: $dark-nav;
            }

            &::placeholder {
              font-weight: 400 !important;
              font-size: 14px !important;
              line-height: 29px !important;
              color: #000000 !important;
            }
          }
        }

        .helpWrapper {
          display: flex;
          align-items: center;
          margin-bottom: 37px;

          .helpIcon {
            margin-right: 13px;
          }

          .helpLink {
            text-decoration: underline;
            font-weight: 600;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            text-decoration-line: underline;
            color: $dark-nav;
          }
        }

        .submitBtn {
          background: $primary;
          color: #ffffff;
          width: 100%;
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
          height: 38px;
          border: none;

          &:hover {
            background: $primary;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.addFileBtn {
  background: $primary !important;
  color: #ffffff !important;
  width: 411px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  height: 38px !important;
  border: none !important;

  &:hover {
    background: $primary !important;
    color: #ffffff !important;
  }
}

.spinner {
  position: relative !important;
  top: 44% !important;
  left: 46%;
}

.urlTab {
  height: 100%;
}
