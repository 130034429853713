@import 'sources/styles/variables.scss';

.uploadSection {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 24px 30px 24px 24px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 16px;

  .topUploadWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .info {
      display: flex;
      flex-direction: column;

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $dark-nav;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        color: $dark-nav;
      }
    }

    .uploadBtn {
      background-color: $secondary;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-right: 12px;
      height: 32px;
      border: 1px solid $secondary;

      &:hover,
      &:active,
      &:focus {
        background-color: $secondary;
        color: #ffffff;
        border: 1px solid $secondary;
      }

      & > span {
        height: auto !important;
      }
    }

    .replaceBtn {
      background: #ffffff;
      color: $dark-nav;
      border: 1px solid #c6c8d2;
      font-weight: 700;

      &:hover,
      &:active,
      &:focus {
        background: #ffffff;
        color: $dark-nav;
        border: 1px solid #c6c8d2;
      }
    }
  }

  .imgWrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    .imgInfo {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 16px;

      .fileName {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: $secondary;
      }

      .downloadLink {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #62636d;
        margin-left: auto;
        border: none;
        padding-right: 0;

        span {
          text-decoration: underline !important;
        }
      }
    }

    .canvasAsset {
      width: 0;
      height: 0;
    }

    .assetImgWrapper {
      background: linear-gradient(68deg, #6d6d6d 50%, #646464 50%);
      width: 450px;
      height: 180px;
      border-radius: 8px;

      .assetImg {
        width: 450px;
        height: 180px;
        border-radius: 8px;
      }
    }
  }
}

.spinAsset {
  position: absolute !important;
  top: 49% !important;
  left: 47% !important;
  color: $media-item-loader !important;
}

.spinText {
  position: absolute;
  top: 73%;
  left: 44%;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 10px;
  color: #ffffff;
}

.doneArrow {
  width: 19px;
  height: 17px;
  position: relative;
  top: 4px;
}
