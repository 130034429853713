@import 'sources/styles/variables.scss';

.form {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  max-width: 560px;
  padding: 57px 75px !important;

  @media screen and (min-width: 724px) {
    padding: 30px 52px !important;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 56px;
      border: 1px solid #efefef;
      cursor: default;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: $dark-nav;
      margin-left: 18px;
    }
  }

  .formItem {
    .formInput {
      border: 1px solid $light-blue;
      border-radius: 4px;
      margin-bottom: 16px;
      padding: 7px 15px;

      input {
        font-weight: normal;
        font-size: 14px;
        line-height: 29px;
        color: $dark-nav;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 29px;
        color: #000000;
      }
    }
  }

  .helpWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 37px;

    .helpIcon {
      margin-right: 13px;
    }

    .helpLink {
      text-decoration: underline;
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      text-align: center;
      text-decoration-line: underline;
      color: $dark-nav;
    }
  }

  .submitBtn {
    background: $light-stroke;
    color: #ffffff;
    border-color: $light-stroke;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    height: 38px;
    border: none;

    &:hover,
    &:focus {
      background: $primary;
      color: #ffffff;
      border-color: $light-stroke;
    }
  }

  .active {
    background: $primary;
  }
}
