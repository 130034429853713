@import 'sources/styles/variables.scss';

.loginWrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  // max-width: 1440px;
  margin: 0 auto;

  .content {
    display: flex;
    height: inherit;
    justify-content: center;

    .formWrapper {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (min-width: 1441px) {
        width: 35%;
      }

      .form {
        width: 300px;

        .title {
          font-weight: bold;
          font-size: 36px;
          max-width: 300px;
          line-height: 52px;
          margin-bottom: 30px;
        }

        .formItem {
          margin-bottom: 0;

          .formInput {
            border: none;
            border-bottom: 1px solid #a0a0a0;
            margin-bottom: 6px;
            padding: 4px 10px;

            input {
              font-weight: normal;
              font-size: 14px;
              line-height: 29px;
              color: $dark-nav;

              &::placeholder {
                color: $dark-nav !important;
                font-size: 14px !important;
                line-height: 29px !important;
              }

              &:-webkit-autofill,
              &:-webkit-autofill:focus {
                transition: background-color 600000s 0s, color 600000s 0s;
              }
            }

            svg {
              position: relative;
              right: 10px;
            }
          }
        }

        .userName {
          margin-bottom: 20px;
        }

        .forgotLink {
          display: block;
          text-decoration: underline;
          color: #0078ff;
          font-weight: 500;
          font-size: 10px;
          line-height: 25px;
          margin-bottom: 20px;
        }

        .submitBtn {
          width: 100%;
          background: $light-stroke;
          color: #ffffff;
          border-color: $light-stroke;
          margin-bottom: 8px;
          font-weight: bold;
          font-size: 14px;
          padding: 10px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .active {
          background: $primary;
        }

        .formText {
          display: block;
          text-align: center;
          font-weight: 500;
          font-size: 10px;
          line-height: 25px;
          color: #000000;

          .registerLink {
            color: #000000;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }

    .picture {
      min-width: 830px;
      background-color: #ffffff;
      background-image: url('../../sources/images/login/picture.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.spinner {
  position: relative !important;
  top: 44% !important;
  left: 46%;
}
