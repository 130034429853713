@import 'sources/styles/variables.scss';

.withoutCategories {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: 140px;

  .text {
    max-width: 336px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $dark-nav;
    margin-bottom: 35px;
  }

  .monitorPicture {
    max-width: 400px;
    max-height: 264px;
    margin-bottom: 36px;
  }

  .createNewCategoryBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    height: 40px;

    & > svg {
      margin-right: 5px;

      path {
        fill: #ffffff;
      }
    }
  }
}
