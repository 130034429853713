@import 'sources/styles/variables.scss';

.videosWrapper {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;

  .uploadMediaItem {
    background: #d1dbe5;
    // background-image: url('../../../../sources/images/content/default-img-bg.png');
    background-size: contain;
    background-repeat: initial;
    border-radius: 10px;
    min-width: 216px;
    height: 148px;
    margin: 10px 8px 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    z-index: 10;

    .mediaItemImg {
      border-radius: 10px;
      width: 216px;
      height: 148px;
    }

    .text {
      position: absolute;
      bottom: 46px;
    }

    .overlay {
      position: absolute;
      min-width: 216px;
      height: 148px;
      background-color: rgba(255, 255, 255, 0.6);
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;

      .doneImg {
        position: absolute;
      }
    }

    &:hover .mediaItemControls {
      top: 0;
    }

    &:hover .mediaItemTitleWrapper {
      bottom: 0;
    }

    .mediaItemControls {
      position: absolute;
      top: -40px;
      left: 0;
      width: 100%;
      height: 40px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      transition: all 0.5s ease;

      .checkboxMediaItem {
        position: relative;

        :global {
          .ant-checkbox:hover .ant-checkbox-inner {
            border-color: $primary !important;
          }

          .ant-checkbox-inner::after {
            left: 5px !important;
          }

          .ant-checkbox .ant-checkbox-inner {
            border-radius: 20px !important;
            width: 22px;
            height: 22px;
          }

          .ant-checkbox-checked .ant-checkbox-inner {
            border-radius: 10px !important;
            background-color: $primary;
            border-color: $primary;
          }

          .ant-checkbox-checked::after {
            border: none !important;
          }
        }
      }
    }

    .mediaItemTitleWrapper {
      position: absolute;
      bottom: -47px;
      left: 0;
      width: 100%;
      height: 47px;
      background: rgba(15, 15, 15, 0.2);
      backdrop-filter: blur(10px);
      transition: all 0.5s ease;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .title {
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #ffffff;
        margin: 4px 8px 0;
        text-align: left;
      }
    }
  }

  .disabled {
    pointer-events: none;
  }

  .selectedUploadMediaItem {
    outline: 3px solid $primary !important;
  }
}

.mediaItemDropdown {
  position: relative;

  .mediaItemDropdownLink {
  }
}

.mediaItemDropdownLink {
  &:hover {
    background-color: #ffffff !important;
  }
}

.removeBtn {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  color: #000000 !important;
  padding: 0 8px !important;

  &:hover {
    color: #000000 !important;
  }
}

.importedInAnotherCategory {
  position: absolute;
  min-width: 46px;
  height: 43px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .circle {
    width: 25px !important;
  }

  .doneImg {
    position: absolute;
    width: 12px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
}

.backArrowLeft {
  border: none !important;
  margin-right: 5px;
}

.onePlaylistTopWrapper {
  padding: 26px 24px 26px 0;
  border-bottom: 1px solid $light-blue;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .playlistTitle {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #001b39;
    flex: 1;
  }
}

.hasInCategory {
  pointer-events: none;
}
