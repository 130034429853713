@import 'sources/styles/variables.scss';

.splashScreen,
.channelLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  height: 548px;

  &:hover {
    border-color: #d9d9d9 !important;
  }

  .uploadTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #0078ff;
    margin-top: 43px;
    margin-bottom: 45px;
  }

  .uploadText {
    display: block;
    background: #ffffff;
    border: 2px dashed #d1dbe5;
    box-sizing: border-box;
    border-radius: 10px;
    width: 216px;
    height: 80px;
    margin: 40px auto 54px;
    text-align: center;
    padding-top: 20px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #b2b2b2;

    .link {
      display: block;
      text-decoration: underline;
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      text-decoration-line: underline;
      color: $dark-nav;
    }
  }
}

.logoWrapper {
  position: relative;
  max-width: 400px;
  margin: 0 auto;

  .viewWrapper {
    position: absolute;
    top: 0;
    background: linear-gradient(68deg, #373737 50%, #222222 50%);
    width: 402px;
    height: 226px;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    overflow: hidden;

    .logo {
      border: 1.4px dashed #ffffff;
      width: 86px;
      min-height: 40px;
      color: #ffffff;
      font-family: 'Poppins';
      text-transform: uppercase;
      font-style: normal;
      font-weight: 400;
      font-size: 7px;
      line-height: 7px;
      background: rgba(255, 255, 255, 0.3);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 15px;
      left: 22px;
    }

    .description {
      margin: 59px 0 8px 22px;
      max-width: 172px;
      text-align: left;

      .descriptionTitle {
        text-align: left;
        font-style: normal;
        font-weight: 400;
        font-size: 8.5px;
        line-height: 7px;
      }

      .descriptionText {
        font-style: normal;
        font-weight: 400;
        font-size: 5.5717px;
        line-height: 7px;
      }
    }

    .categoriesList {
      .category {
        height: 60px;
        overflow: hidden;
        padding-left: 22px;
        text-align: left;

        .categoryTitle {
          font-style: normal;
          font-weight: 400;
          font-size: 8.5px;
          line-height: 7px;
        }

        .categoryItems {
          padding-left: 0;
          list-style: none;
          display: flex;
          justify-content: space-between;
          position: relative;
          right: 85px;
          width: 125%;

          li {
            width: 75px;
            height: 40px;
            background: rgba(196, 196, 196, 0.2);
          }

          .selectedItem {
            outline: 2px solid #896cfc;
            outline-offset: 3px;
          }
        }
      }
    }
  }
}

.splashWrapper {
  position: relative;
  max-width: 400px;
  margin: 0 auto;

  .viewWrapper {
    position: absolute;
    top: 5px;
    left: 6px;
    background: linear-gradient(68deg, #6d6d6d 50%, #646464 50%);
    width: 390px;
    height: 216px;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    overflow: hidden;
    border: 1.39985px dashed #ffffff;
    outline: 10px solid #000000;

    .splashBg {
      position: absolute;
      left: 0;
      top: 0;
      width: 390px;
      height: 216px;
    }

    .status {
      width: 60px;
      position: absolute;
      left: 42%;
      top: 90px;
    }
  }
}

.canvasSplash {
  width: 0;
  height: 0;
}

.canvasLogo {
  width: 0;
  height: 0;
}

.spinAsset {
  position: absolute !important;
  top: 39% !important;
  left: 46% !important;
  color: $media-item-loader !important;
}

.spinText {
  position: absolute;
  top: 68%;
  left: 43%;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 10px;
  color: #ffffff;
}
