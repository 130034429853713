@import 'sources/styles/variables.scss';

.filters {
  :global {
    .ant-drawer-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 29px;
      color: $dark-nav;
    }
  }

  .filterSwitch {
    background: #f5f6fa;
    width: 48px;
    height: 24px;
    opacity: 0.5;

    :global {
      .ant-switch-handle::before {
        background: #0078ff !important;
      }
    }
  }

  :global {
    .ant-switch-checked {
      opacity: 1 !important;
    }
  }

  .closeFiltersBtn {
    width: 44px;
    height: 44px;
    background: #f3f3f3;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    &:hover {
      border: none;
      background: #f3f3f3;
    }
  }

  .filtersTitle {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 15px;
  }

  .selectFilterCategories {
    .categories {
      width: 100%;
      margin-bottom: 42px;

      :global {
        .ant-select-selection-item-content {
          div {
            display: none !important;
          }
        }

        .ant-select-selection-overflow-item span {
          background: #0078ff;
          color: #ffffff;
          border-radius: 50px;
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          padding: 0 4px;
        }
      }
    }
  }

  .filtersWrapper {
    margin-bottom: 40px;

    .filterItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .statusText {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
      }
    }
  }

  .sourceFilters {
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;

    .checkboxFilterSource {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      margin-left: 0;
      position: relative;

      :global {
        .ant-checkbox:hover .ant-checkbox-inner {
          border-color: $secondary !important;
        }

        .ant-checkbox-inner::after {
          left: 5px !important;
        }

        .ant-checkbox .ant-checkbox-inner {
          border-radius: 20px !important;
          width: 22px;
          height: 22px;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          border-radius: 10px !important;
          background-color: $secondary;
          border-color: $secondary;
        }

        .ant-checkbox-checked::after {
          border: none !important;
        }
      }

      span:nth-child(2) {
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0;

        .sourceLinkIcon {
          width: 24px;
          height: 24px;
          margin-right: 0;
          position: relative;
          right: 3px;
        }

        svg {
          margin-right: 6px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .viewsFilters {
    margin-bottom: 42px;

    .viewsTitleWrapper {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      .viewsValues {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        color: #0078ff;

        .eyeIcon {
          margin-left: 6px;
        }
      }
    }

    .viewsSlider {
      div:nth-child(2) {
        background: #0078ff;
      }

      div:nth-child(4),
      div:nth-child(5) {
        border: solid 2px #0078ff;
      }
    }
  }

  .applyFiltersBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
}

.categoryItem {
  position: relative;

  :global {
    .ant-select-item-option-content {
      max-width: 150px !important;
    }

    div[aria-selected='true'] .selectedCategoryImg {
      background-image: url('../../../sources/images/libary/selected-category.svg') !important;
    }
  }
}

.selectedCategoryImg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 10px;
  background-image: url('../../../sources/images/libary/unselected-category.svg');
  background-position: center;
  background-size: cover;
}
