@import 'sources/styles/variables.scss';

.sideBarWrapper {
  width: 250px;
  display: flex;
  flex-direction: column;
  padding: 0 0 50px;
  border-right: 1px solid $light-blue;

  position: sticky;
  left: 0;
  top: 50px;
  height: 100%;

  .appProgressWrapper {
    padding: 38px 34px 0 24px;

    .appProgressText {
      font-size: 14px;
      line-height: 16px;
      color: #001b39;
    }

    .appProgressBar {
      margin-bottom: 0;
    }

    .appStepsText {
      font-size: 10px;
      line-height: 15px;
      color: #0078ff;
      border-bottom: 1px solid $light-blue;
      display: block;
      padding-bottom: 35px;
    }
  }
}
