@import 'sources/styles/variables.scss';

.systemColorsWrapper {
  padding: 37px 17px 30px;
  background: #fafafa;
  border-radius: 8px;
  border: 1px dashed #d9d9d9;
  display: flex;

  .selectColors {
    border-right: 1px solid $light-blue;
    width: 420px;

    .colorsTitle {
      font-weight: 600;
      font-size: 13px;
      line-height: 25px;
      color: #000000;
    }

    .colorItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .colorBtn {
        width: 64px;
        height: 64px;
        background: #373737;
        border: 1px solid #f6fafe;
        border-radius: 10px;
        margin-right: 25px;
      }

      .colorBtnSelected1 {
        background: #896cfc;
      }

      .colorInfo {
        .colorTitle {
          font-weight: 600;
          font-size: 13px;
          line-height: 25px;
          color: #000000;
          margin-bottom: 0;
        }

        .colorValue {
          font-weight: 300;
          font-size: 13px;
          line-height: 20px;
          color: #000000;
        }
      }
    }
  }

  .viewSelectedColors {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 402px;
    height: 266px;
    overflow: hidden;
    margin: auto 0;

    .viewWrapper {
      position: absolute;
      top: 0;
      background-color: #373737;
      width: 402px;
      height: 226px;
      display: flex;
      flex-direction: column;
      color: #ffffff;

      .description {
        margin: 22px 0 38px 22px;
        max-width: 172px;

        .descriptionTitle {
          font-style: normal;
          font-weight: 400;
          font-size: 8.5px;
          line-height: 7px;
        }

        .descriptionText {
          font-style: normal;
          font-weight: 400;
          font-size: 5.5717px;
          line-height: 7px;
        }
      }

      .categoriesList {
        .category {
          margin-bottom: 6px;
          overflow: hidden;
          padding-left: 22px;

          .categoryTitle {
            font-style: normal;
            font-weight: 400;
            font-size: 8.5px;
            line-height: 7px;
          }

          .categoryItems {
            padding-left: 0;
            list-style: none;
            display: flex;
            justify-content: space-between;
            position: relative;
            right: 85px;
            width: 125%;

            li {
              width: 75px;
              height: 40px;
              background: rgba(196, 196, 196, 0.2);
            }

            .selectedItem {
              outline: 2px solid #896cfc;
              outline-offset: 3px;
            }
          }
        }
      }
    }
  }
}

.collapseColors {
  max-width: 300px;
  border: none !important;

  :global {
    .ant-collapse-content {
      border-top: none !important;
    }
  }

  .colorPickerWrapper {
    border: 1px solid $light-blue;
    border-radius: 8px;
    padding: 17px 8px 8px 8px;
    max-height: 240px;

    .selectedColor {
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      background: $light-blue;
      border-radius: 90px;
      padding: 4px 8px;
      margin-bottom: 27px;
    }

    .sliderPicker {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      height: 64px;
    }
  }
}

.controlsBtnWrapper {
  margin-top: 20px;

  .controlsBtn {
    margin-right: 12px;
    background: $secondary;
    color: #ffffff;
    border: none;
    align-items: center;
    justify-content: center;
    height: 40px;

    &:hover,
    &:active,
    &:focus {
      background: $secondary !important;
      color: #ffffff !important;
    }
  }
}
