@import 'sources/styles/variables.scss';

.menu {
  border: none;
  padding-top: 35px !important;

  .menuItem {
    display: flex;
    align-items: center;
    padding: 0 20px;
    transition: all 0.5s ease;

    svg > path {
      transition: all 0.5s ease;
    }

    &:hover svg > path {
      stroke: $primary;
    }

    &:hover {
      border-left: 3px solid $primary;
    }

    .menuLink {
      transition: all 0.5s ease;
      margin-bottom: 18px;

      &:hover {
        color: $primary;
      }
    }
  }

  .activeLink {
    border-left: 3px solid $primary;

    .menuLink {
      color: $primary;
    }

    svg > path {
      stroke: $primary;
    }
  }
}
