@import 'sources/styles/variables.scss';

.previewPage {
  height: 95%;
  background-color: #fff !important;
  border-radius: 8px 18px 18px 8px !important;

  :global {
    .ant-modal-content {
      height: 100% !important;
      border-radius: 8px 18px 18px 8px !important;
    }

    .ant-modal-body {
      height: 100%;
      border-radius: 8px 18px 18px 8px !important;

      &::-webkit-scrollbar {
        height: 5px;
        width: 14px;
      }

      &::-webkit-scrollbar-track {
        background: #c8c8c8;
        border-radius: 0 6px 6px 0;
      }

      &::-webkit-scrollbar-thumb {
        background: #0078ff;
        border-radius: 0 6px 6px 0;
        max-width: 10px;
      }
    }

    .ant-modal-body,
    .ant-modal-footer {
      padding: 0 !important;
      border: none !important;
    }

    .ant-modal-close-x {
      position: relative;
      right: 17px;
    }
  }

  .previewWrapper {
    border-radius: 8px 18px 18px 8px !important;

    .top {
      position: relative;
      padding: 64px 40px 0 50px;
      height: 465px;
      display: flex;

      .info {
        z-index: 100 !important;
      }

      .splashImg {
        width: 787px;
        height: 443px;
        position: absolute;
        right: 0;
        top: 0;
        background-size: 787px 443px !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
      }

      .logo {
        width: 200px;
        height: 100px;
      }

      .title {
        margin: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 46px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 640px;
        margin-top: 25px;
      }

      .description {
        margin-top: 24px;
        margin-bottom: 130px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #ffffff;
        text-overflow: ellipsis;
        width: 640px;
        overflow-y: hidden;
        height: 150px;
      }
    }

    .categories {
      padding: 0 40px 0 50px;
    }
  }
}

.logoText {
  font-style: normal;
  font-weight: 400;
  font-size: 37.0734px;
  line-height: 34px;
}

.splashPreview {
  img {
    width: 100%;
    height: 100vh;
  }
}

.spin {
  position: absolute !important;
  height: 148px !important;
  top: 42% !important;
  left: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 100 !important;
  color: $media-item-loader !important;
}
