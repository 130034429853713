@import 'sources/styles/variables.scss';

.tabs {
  border-bottom: none;
  flex: 1;
  width: 100%;
  max-height: 47vh;
  overflow-y: auto !important;
  height: 47vh;

  @media screen and (min-height: 764px) {
    max-height: 50vh;
  }

  @media screen and (min-height: 900px) {
    max-height: 56vh;
  }

  @media screen and (min-height: 980px) {
    max-height: 59vh;
  }

  @media screen and (min-height: 1000px) {
    max-height: 61vh;
  }

  @media screen and (min-height: 1100px) {
    max-height: 65vh;
  }

  @media screen and (min-height: 1200px) {
    max-height: 69vh;
  }

  @media screen and (min-height: 1300px) {
    max-height: 71vh;
  }

  @media screen and (min-height: 1400px) {
    max-height: 72vh;
  }

  @media screen and (min-height: 1500px) {
    max-height: 73vh;
  }

  @media screen and (min-height: 1600px) {
    max-height: 74vh;
  }

  @media screen and (min-height: 1700px) {
    max-height: 75vh;
  }

  @media screen and (min-height: 1800px) {
    max-height: 76vh;
  }

  @media screen and (min-height: 1900px) {
    max-height: 77vh;
  }

  @media screen and (min-height: 2000px) {
    max-height: 78vh;
  }

  @media screen and (min-height: 2100px) {
    max-height: 79vh;
  }

  @media screen and (min-height: 2200px) {
    max-height: 80vh;
  }

  @media screen and (min-height: 2300px) {
    max-height: 81vh;
  }

  @media screen and (min-height: 2400px) {
    max-height: 82vh;
  }

  @media screen and (min-height: 2500px) {
    max-height: 83vh;
  }

  @media screen and (min-height: 2600px) {
    max-height: 84vh;
  }

  @media screen and (min-height: 2700px) {
    max-height: 85vh;
  }

  @media screen and (min-height: 2800px) {
    max-height: 86vh;
  }

  @media screen and (min-height: 2900px) {
    max-height: 87vh;
  }

  @media screen and (min-height: 3000px) {
    max-height: 88vh;
  }

  // @media screen and (min-width: 3000px) {
  //   max-height: 1400px;
  // }

  &::-webkit-scrollbar {
    height: 5px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #c8c8c8;
    border-radius: 22px;
  }

  &::-webkit-scrollbar-thumb {
    background: #0078ff;
    border-radius: 22px;
    max-width: 10px;
  }

  .tabOperations {
    display: flex;

    .uploadBtn {
      background-color: $secondary;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-right: 12px;
      height: 42px;

      & > span {
        height: auto !important;
      }
    }

    .tabOperationsBtn {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: $darken-gray;
      height: 42px;
      background: #ffffff;
      border: 1px solid $light-blue;
      margin-right: 12px;

      svg {
        margin: 0 7px;
      }

      & > span {
        height: auto !important;
      }

      & > label {
        height: 22px;
        display: flex;
        flex-direction: row-reverse;
        align-items: baseline;
        position: relative;
        bottom: 3px;

        span:nth-child(2) {
          position: relative;
          bottom: 3px;
        }
      }
    }
  }

  .tabVideos {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(36, 41, 70, 0.75);

    span {
      // height: 100%;
    }
  }

  :global {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $dark-nav;
    }

    .ant-tabs-content {
      height: 100% !important;
    }

    .ant-tabs-nav {
      padding-left: 26px !important;
      position: sticky !important;
      top: 0 !important;
      left: 0 !important;
      height: 56px !important;
      z-index: 1000 !important;
      background-color: #ffffff !important;
    }

    .ant-tabs-nav::before {
      border-bottom: none !important;
    }

    .ant-tabs-nav-operations {
      display: none !important;
    }

    .ant-tabs-tab {
      &:hover {
        color: $dark-nav;
      }
    }

    .ant-tabs-ink-bar {
      background: $primary;
      bottom: 8px !important;
    }
  }
}

.checkbox {
  position: relative;

  :global {
    .ant-checkbox:hover .ant-checkbox-inner {
      border-color: $primary !important;
    }

    .ant-checkbox-inner::after {
      left: 5px !important;
    }

    .ant-checkbox .ant-checkbox-inner {
      border-radius: 20px !important;
      width: 22px;
      height: 22px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border-radius: 10px !important;
      background-color: $primary;
      border-color: $primary;
    }

    .ant-checkbox-checked::after {
      border: none !important;
    }
  }
}

.uploadWrapper {
  background: #ffffff;
  border: 2px dashed $light-stroke;
  border-radius: 4px;
  padding: 0 10px !important;
  min-height: 100% !important;
  height: auto !important;

  :global {
    .ant-upload-drag-container {
      display: flex !important;
      flex-wrap: wrap !important;
      min-height: 100% !important;
    }

    .ant-upload.ant-upload-drag {
    }
  }

  .hasInCategory {
    pointer-events: none;
  }

  .uploadMediaItem {
    background: #d1dbe5;
    background-image: url('../../../../sources/images/content/default-img-bg.png');
    background-size: contain;
    background-repeat: initial;
    border-radius: 10px;
    min-width: 216px;
    height: 148px;
    margin: 10px 8px 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    z-index: 10;

    .text {
      position: absolute;
      bottom: 46px;
    }

    .overlay {
      position: absolute;
      min-width: 216px;
      height: 148px;
      background-color: rgba(255, 255, 255, 0.6);
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;

      .doneImg {
        position: absolute;
      }
    }

    &:hover .mediaItemControls {
      top: 0;
    }

    &:hover .mediaItemTitleWrapper {
      bottom: 0;
    }

    .mediaItemControls {
      position: absolute;
      top: -40px;
      left: 0;
      width: 100%;
      height: 40px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      transition: all 0.5s ease;

      .checkboxMediaItem {
        position: relative;

        :global {
          .ant-checkbox:hover .ant-checkbox-inner {
            border-color: $primary !important;
          }

          .ant-checkbox-inner::after {
            left: 5px !important;
          }

          .ant-checkbox .ant-checkbox-inner {
            border-radius: 20px !important;
            width: 22px;
            height: 22px;
          }

          .ant-checkbox-checked .ant-checkbox-inner {
            border-radius: 10px !important;
            background-color: $primary;
            border-color: $primary;
          }

          .ant-checkbox-checked::after {
            border: none !important;
          }
        }
      }
    }

    .mediaItemTitleWrapper {
      position: absolute;
      bottom: -47px;
      left: 0;
      width: 100%;
      height: 47px;
      background: rgba(15, 15, 15, 0.2);
      backdrop-filter: blur(10px);
      transition: all 0.5s ease;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .title {
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #ffffff;
        margin: 4px 8px 0;
        text-align: left;
      }
    }
  }

  .disabled {
    pointer-events: none;
  }

  .selectedUploadMediaItem {
    outline: 3px solid $primary !important;
  }

  &:hover {
    border: 2px dashed $light-stroke;
  }
}

.mediaItemDropdown {
  position: relative;

  .mediaItemDropdownLink {
  }
}

.mediaItemDropdownLink {
  &:hover {
    background-color: #ffffff !important;
  }
}

.removeBtn {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  color: #000000 !important;
  padding: 0 8px !important;

  &:hover {
    color: #000000 !important;
  }
}

.searchField {
  border: none !important;

  &::placeholder {
    color: $darken-gray !important;
  }

  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
}

.mediaItemImg {
  border-radius: 10px;
  width: 216px;
  height: 148px;
}

.importedInAnotherCategory {
  position: absolute;
  min-width: 46px;
  height: 43px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .circle {
    width: 25px !important;
  }

  .doneImg {
    position: absolute;
    width: 12px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  position: relative;
  top: 16px;
}

.draggingMediaItem {
  position: absolute;
  min-width: 216px;
  height: 148px;
  background-color: rgba(255, 255, 255, 0.6) !important;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.failedOverlay {
  position: absolute;
  min-width: 218px;
  height: 148px;
  background-color: rgba(255, 255, 255, 0.4);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .failedIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px !important;
    border-radius: 22px;
    background: rgba(36, 41, 70, 0.7);
    border: 3px solid #f1557f;

    .failedIcon {
      color: #f1557f;
      font-weight: 900;
      font-size: 14px;
      position: relative;
      top: 10px;
    }
  }
}
