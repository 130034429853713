@import 'sources/styles/variables.scss';

.editModal {
  position: relative;
  border-radius: 6px;

  :global {
    .ant-modal-footer {
      .ant-btn-default {
        border-radius: 17px !important;
      }

      .ant-btn-primary {
        background-color: $secondary !important;
        border-radius: 17px !important;
        color: #ffffff !important;
      }
    }

    .ant-modal-content {
      height: 100% !important;
      border-radius: 6px !important;
    }

    .ant-modal-body {
      height: 100%;
      border-radius: 6px !important;
    }

    .ant-modal-header {
      border-radius: 6px !important;
    }
  }

  .textarea {
    width: 100%;
    resize: none;
    background: #ffffff;
    border: 1px solid #c6c8d2;
    border-radius: 8px;
    padding: 16px;
    color: #62636d;
    height: 175px;

    &:focus {
      border-color: #c6c8d2 !important;
      box-shadow: none;
    }

    &:hover {
      border: 1px solid #c6c8d2;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: #aaacb9;
    }
  }
}
