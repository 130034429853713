@import 'sources/styles/variables.scss';

.progressBarWrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 11px;
  right: 160px;
  z-index: 1000;
  width: 300px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 1px 4px rgba(0, 27, 57, 0.13);
}

.progressBar {
  display: flex;
  flex-direction: column;
}

.text {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: $dark-nav;
}
